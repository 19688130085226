* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑",
    sans-serif;
  /* transition: all 0.4s ease; */
}
/* ===== Colours ===== */
:root {
  --body-color: #fff;
  --nav-color: #e5e4e2;
  --side-nav: #010718;
  --text-color: #42b0d5;
  --search-bar: #f2f2f2;
  --search-text: #010718;
}
body {
  height: 100%;
  background-color: var(--body-color);
}
body.dark {
  --body-color: #18191a;
  --nav-color: #242526;
  --side-nav: #242526;
  --text-color: #ccc;
  --search-bar: #242526;
}
nav {
  position: relative;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: var(--nav-color);
  z-index: 100;
  align-items: flex-start;
}
body.dark nav {
  border: 1px solid #393838;
}
nav .nav-bar {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--nav-color);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px var(--text-color);
}
nav .nav-bar .nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .nav-bar .nav-container .logo-navLogo a {
  font-size: 1.15rem;
  font-weight: 500;
  color: var(--text-color);
  text-decoration: none;
  white-space: nowrap;
}
nav .nav-bar .userProfile{
   display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8rem;
}
nav .nav-bar .userProfile span{
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--text-color);
  margin-right: 0.4rem;
}
.home {
  position: relative;
  height: 100%;
  width: 100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* background-color: var(--body-color);
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 500;
  text-align: center; */
}


.custom-table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
   /* Ensures columns are fixed size */
}

.custom-table thead {
  background-color: #87CEEB;
  /* color: white; */
  position: sticky;
  top: 0;
  /* z-index: 1; */
   /* Ensures the header is on top */
  display: table;
  width: 100%;
}

.custom-table th, .custom-table td {
  /* border: 1px solid #87CEEB; */
  /* width: auto; */
  padding: 8px;
  text-align: center;
}



.custom-table th{
  text-align: center;
}

/* .custom-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
} */

.custom-table tbody tr:hover {
  background-color: #ddd;
}

.custom-table:not(.exclude-width-rule) th:first-child, 
.custom-table:not(.exclude-width-rule) td:first-child {
  width: 7vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(2), 
.custom-table:not(.exclude-width-rule) td:nth-child(2) {
  width: 9vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(3), 
.custom-table:not(.exclude-width-rule) td:nth-child(3) {
  width: 5vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(4), 
.custom-table:not(.exclude-width-rule) td:nth-child(4) {
  width: 12vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(5), 
.custom-table:not(.exclude-width-rule) td:nth-child(5) {
  width: 9vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(6), 
.custom-table:not(.exclude-width-rule) td:nth-child(6) {
  width: 9vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(7), 
.custom-table:not(.exclude-width-rule) td:nth-child(7) {
  width: 5vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(8), 
.custom-table:not(.exclude-width-rule) td:nth-child(8) {
  width: 5vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(9), 
.custom-table:not(.exclude-width-rule) td:nth-child(9) {
  width: 12vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(10), 
.custom-table:not(.exclude-width-rule) td:nth-child(10) {
  width: 8vw;
}

.custom-table:not(.exclude-width-rule) th:nth-child(11),
.custom-table:not(.exclude-width-rule) td:nth-child(11) {
  width: 6vw;
}

.custom-table th {
  background: #87CEEB; /* Add a background color to prevent cells from showing through */
  position: sticky;
  top: 0;
}
.custom-table td, .custom-table th{
  /* min-width: 150px; 
  min-height: 150px; 
  max-height: 200px; */
  white-space: normal; /* Allows text to wrap to next line */
  word-wrap: break-word; /* Breaks long words to next line */
}
.custom-table tbody {
  display: grid;
  overflow-y: auto;
  border: 1px solid #87CEEB;
  border-bottom: none;
  
  overflow-x: hidden;
  /* height: 60vh; */
  /* min-height: 300px; */
  /* max-height: 60vh;  */
}


.custom-table tbody tr {
  display: table;
  width: 100%;
  height: auto;
  table-layout: fixed;
}
.custom-table th{
  border: 2px solid #87CEEB;
}
.custom-table tbody tr:last-child td {  
  border-bottom: none;
}
.custom-table thead th:last-child {
border-right: none;
}

.animated-search-button {
  margin-left: 0.5rem;
  padding: 5px 12px;
  font-size: 16px;
  font-weight: 600;
  background-color: #42b0d5;
  color: white;
  border: none;
  border-radius: 5px;
  transition: transform 0.1s ease-in-out, background-color 0.3s ease;
  cursor: pointer; /* Change pointer style */
}

.animated-search-button:hover {
  background-color: #3598b3; /* Change background color on hover */
}

.animated-search-button:active {
  transform: scale(0.95);
}

/* src/components/Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  z-index: 9999;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}






.search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0;
  position: relative;
}

.search-label {
  padding-right: 0.7rem;
  font-weight: 400;
  margin: 0;
}

.search-Input_suggestions {
  position: relative;
  width: 50%;
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
}

.clear-icon:hover {
  color: #555; /* Change color on hover */
}

.search-input {
  padding: 0.3rem 2rem 0.3rem 0.3rem;
  width: 100%;
  border-radius: 5px;
}

.suggestions-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Remove margin to align with input */
  width: 100%; /* Set width to 100% of the parent container */
  max-height: 200px; /* Limit the height */
  overflow-y: auto; /* Make it scrollable */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  border-top: none; /* Remove the top border to connect with input */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1000; /* Ensure it appears above other elements */
  position: absolute; /* Position it absolutely */
  top: 100%; /* Position it directly below the input */
  left: 0; /* Align it to the left of the parent container */
}

/* Hide scrollbar for WebKit browsers */
.suggestions-dropdown::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.suggestions-dropdown {
  scrollbar-width: none; /* Firefox */
}

.suggestion-item {
  padding: 7px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
  /* border-bottom: 1px solid #ccc; */
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}


/* Add this to your App.css or a relevant CSS file */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  /* width: 200px; */
  background-color: #f44336;
  color: #fff;
  text-align: center;
  font-size: 12px;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -100px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.userProfile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: #42b0d5;
}

.userProfile span {
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute; /* Position the dropdown absolutely */
  top: 100%; /* Position it directly below the user profile */
  left: 0; /* Align it to the left edge of the user profile */
  display: block;
  background-color: var(--nav-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow for better visibility */
  z-index: 1000;
  font-size: 14px; /* Slightly larger font size */
  border-top: none;
  border-radius: 0 0 5px 5px;
  width: 100%; /* Fixed width for consistency */
  padding: 10px 0; /* Padding for the dropdown */
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 0px 10px; /* More padding for better spacing */
  cursor: pointer;
  display: block;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.dropdown-menu li:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Light hover effect */
}

.dropdown-menu li a {
  color: #42b0d5;
  text-decoration: none;
  display: block;
  width: 100%;
}

.dropdown-menu li a:hover {
  color: #18191a; /* Change text color on hover */
}

.theme-toggle-button {
  margin-right: 1rem;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #42b0d5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.theme-toggle-button:hover {
  background-color: #3598b3;
}

.nav-actions {
  display: flex;
  align-items: center;
}

.table-container {
  margin-top: 3vh;
  height: 65vh;
  display: flex;
  flex-direction: column;
}

@media (max-height: 870px) {
  .table-container {
    height: 62vh; 
  }
}

@media (max-height: 790px) {
  .table-container {
    height: 58vh; 
  }
}

@media (max-height: 700px) {
  .table-container {
    height: 55vh;
  }
}

@media (max-height: 630px) {
  .table-container {
    height: 52vh; 
  }
}

@media (max-height: 590px) {
  .table-container {
    height: 48vh; 
  }
}

@media (max-height: 550px) {
  .table-container {
    height: 43vh; 
  }
}

@media (max-height: 490px) {
  .table-container {
    height: 40vh; 
  }
}

@media (max-height: 400px) {
  .table-container {
    height: 35vh; 
  }
}